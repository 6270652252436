// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartConfiguration, ChartAPI } from "c3";
import React from "react";
import styled from "styled-components";

import {
  colorGray20,
  colorGray40,
  colorGray60,
  colorStatusOrange
} from "core/styles/ColorVariables";
import { fontFamily, fontSizeMedium } from "core/styles/StyleVariables";

import useChart from "../hooks/useChart";

require("c3/c3.css");

interface IChartProps {
  chartId?: string;
  className?: string;
  configuration: ChartConfiguration;
  onGenerate?: (chart: ChartAPI | undefined) => void;
}

const Chart = ({
  chartId,
  className,
  configuration,
  onGenerate
}: IChartProps) => {
  const { containerId, containerRef } = useChart(
    configuration,
    chartId,
    onGenerate
  );

  return <div className={className} id={containerId} ref={containerRef} />;
};

const StyledChart = styled(Chart)`
  touch-action: pan-y;
  &.c3 svg {
    font: ${fontSizeMedium} ${fontFamily};
    width: 100%;

    .c3-axis {
      > path {
        display: none;
      }

      .tick > line {
        display: none;
      }

      .tick > text {
        fill: ${colorGray60};
      }

      &.c3-axis-x {
        > .tick:first-of-type:not(:last-of-type) > text {
          text-anchor: start !important;
        }

        > .tick:last-of-type:not(:first-of-type) > text {
          text-anchor: end !important;
        }
      }
    }

    .c3-chart-lines {
      .c3-circles .c3-circle:last-of-type {
        stroke: currentColor;
        stroke-width: 2;
        fill: white;
      }

      .c3-line {
        stroke-width: 2px;
      }
    }

    .c3-grid {
      .c3-ygrids .c3-ygrid {
        stroke: ${colorGray20};
        stroke-dasharray: 0;
        opacity: 0.5;
      }
    }

    .c3-grid-lines {
      .c3-xgrid-line {
        > line {
          stroke: ${colorGray40};
        }
      }

      .c3-ygrid-line {
        .threshold {
          > text {
            fill: ${colorStatusOrange};
            font-weight: bold;
          }

          > line {
            stroke: ${colorStatusOrange};
            stroke-dasharray: 10;
          }
        }
      }
    }
  }
`;

export default StyledChart;
