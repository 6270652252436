// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { AxiosResponse, AxiosError } from "axios";
import { PrimitiveArray, GridLineOptions, DataPoint } from "c3";
import { useState, useEffect, useRef } from "react";

import endpoint, { ApiResponse } from "@apm/endpoint";

import useIsMounted from "common/hooks/useIsMounted";

import useLineChartConfiguration from "./useLineChartConfiguration";

const useLineChart = (
  url: string,
  mapToColumns: (data: any) => Array<[string, ...PrimitiveArray]>,
  mapToLines?: (data: any) => GridLineOptions[],
  zoom?: boolean,
  tooltip?: boolean,
  tooltipContents?: (
    data: DataPoint[],
    defaultTitleFormat: (...args: unknown[]) => unknown,
    defaultValueFormat: (...args: unknown[]) => unknown,
    color: (...args: unknown[]) => unknown
  ) => string
) => {
  const chartRefContainer = useRef<HTMLDivElement>(null);
  const [response, setResponse] = useState<ApiResponse<any>>();
  const isMounted = useIsMounted();
  const configuration = useLineChartConfiguration(
    response && response.data,
    mapToColumns,
    chartRefContainer,
    mapToLines,
    zoom,
    tooltip,
    tooltipContents
  );

  useEffect(() => {
    endpoint({
      url
    })
      .then((response: AxiosResponse<any>) => {
        if (isMounted()) {
          setResponse(new ApiResponse(response));
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted()) {
          setResponse(new ApiResponse(error.response ? error.response : error));
        }
      });

    setResponse(new ApiResponse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return {
    configuration,
    response,
    chartRefContainer
  };
};

export default useLineChart;
