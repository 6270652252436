// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ILicenseData from "../models/ILicenseData";
import ICommonLicense from "../models/ICommonLicense";

const convertCommonLicensesToData = (
  licenses: ICommonLicense[]
): ILicenseData[] => {
  return licenses.map((license) => {
    return {
      library: null,
      version: null,
      licenses: license.Name,
      licensesUrl: license.Url,
      licensesFileName: null,
      projectUrl: license.Url && null,
      licenseType: "common"
    };
  });
};

export default convertCommonLicensesToData;
