// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { PrimitiveArray, DataPoint } from "c3";
import React, { useCallback, useMemo } from "react";
import { FormattedDate, useIntl } from "react-intl";
import styled from "styled-components";

import { getApiUrl } from "@apm/endpoint";
import { Box, Typography } from "@material-ui/core";

import { LineChart } from "common/components/Chart";
import Accordion from "common/components/Accordion";
import { colorGray0 } from "core/styles/ColorVariables";
import {
  spacingNormal,
  spacingXXLarge,
  spacingXLarge,
  spacingGridItem
} from "core/styles/StyleVariables";

import ParameterUnit from "./ParameterUnit";
import ParameterValue from "./ParameterValue";
import IAssetParameterTranslated from "../../models/IAssetParameterTranslated";
import IAssetParameterTrendItems from "../../models/IAssetParameterTrendItems";

import { getStaticMarkup } from "./ParameterChartTooltip";

const parametersTrendUrl =
  "mobile/asset-parameters-trend?assetId={assetId}&parameterName={parameterName}";

interface IParametersItemProps {
  className?: string;
  assetId: string | undefined;
  parameter: IAssetParameterTranslated;
}

const ParametersItem = ({
  assetId,
  className,
  parameter
}: IParametersItemProps) => {
  const intl = useIntl();
  const allowToExpand = useMemo(() => parameter.Type === "Decimal", [
    parameter
  ]);

  const trendUrl = getApiUrl(parametersTrendUrl, {
    assetId: assetId || "",
    parameterName: parameter.Name
  });

  const mapTrendToColumns = (trend: IAssetParameterTrendItems) => {
    const keys = Object.keys(trend);
    const x: [string, ...PrimitiveArray] = ["x"];
    const data: [string, ...PrimitiveArray] = ["data"];

    keys.forEach((k) => {
      const date = new Date(k);
      const value = trend[k];
      x.push(date.toISOString());
      data.push(value);
    });

    return [x, data];
  };

  const tooltipContents = useCallback(
    (
      [d]: DataPoint[],
      defaultTitleFormat: (...args: unknown[]) => unknown,
      defaultValueFormat: (...args: unknown[]) => unknown,
      color: (...args: unknown[]) => unknown
    ) => {
      return getStaticMarkup({
        intl,
        date: d.x,
        name: parameter.TranslatedName,
        value: d.value
      });
    },
    [intl, parameter]
  );

  return (
    <Accordion
      className={className}
      allowToExpand={allowToExpand}
      Content={() => (
        <LineChart
          className="trend-chart"
          mapToColumns={mapTrendToColumns}
          url={trendUrl}
          zoom={true}
          tooltip={true}
          tooltipContents={tooltipContents}
        />
      )}
    >
      <Box className="parameter-info">
        <Typography className="parameter-name" variant="body1">
          {parameter.TranslatedName}
        </Typography>
        <Typography data-qa="parameter-date" variant="body2">
          <FormattedDate value={parameter.Date} />
        </Typography>
      </Box>
      <Box className="parameter-data">
        <ParameterValue
          className="parameter-value"
          type={parameter.Type}
          value={parameter.Value}
          variant="h5"
        />
        {parameter.Unit && (
          <ParameterUnit
            className="parameter-unit"
            unit={parameter.Unit}
            variant="body2"
          />
        )}
      </Box>
    </Accordion>
  );
};

const StyledParametersItem = styled(ParametersItem)`
  padding: ${spacingGridItem} ${spacingXLarge};
  width: 100%;

  .parameter-info {
    width: 100%;

    .parameter-name {
      font-weight: bold;
    }
  }

  .parameter-data {
    text-align: end;
    word-break: normal;

    .parameter-value {
      font-weight: bold;
      margin-left: ${spacingNormal};
      text-align: end;
      white-space: nowrap;
    }
  }

  .trend-chart {
    margin-top: ${spacingXXLarge};

    .progress {
      margin-bottom: ${spacingXXLarge};
      margin-top: ${spacingXXLarge};
    }

    .message {
      margin-bottom: ${spacingXXLarge};
      margin-top: ${spacingXXLarge};
    }
  }

  &.extended {
    background-color: ${colorGray0};
  }
`;

export default StyledParametersItem;
