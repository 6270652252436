// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartAPI, ChartConfiguration, generate } from "c3";
import { useRef, useMemo, useCallback, useEffect } from "react";
import { v4 } from "uuid";

import { colorChartSeries } from "core/styles/ColorVariables";

const useChart = (
  configuration: ChartConfiguration,
  chartId: string = v4(),
  onGenerate?: (chart: ChartAPI | undefined) => void
) => {
  const chartRef = useRef<ChartAPI>();
  const containerId = useMemo(() => `chart-${chartId}`, [chartId]);
  const containerRef = useRef<HTMLDivElement>(null);
  const generateTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    configuration.color = {
      pattern: colorChartSeries
    };
    configuration.size = {
      height: 192
    };
  }, [configuration]);

  const clearGenerateTimeout = useCallback(() => {
    if (generateTimeoutRef.current) {
      clearTimeout(generateTimeoutRef.current);
      generateTimeoutRef.current = undefined;
    }
  }, []);

  const setGenerateTimeout = useCallback(
    (callback: () => void, numberOfTry: number) => {
      clearGenerateTimeout();
      generateTimeoutRef.current = setTimeout(() => {
        clearGenerateTimeout();
        callback();
      }, 200 * numberOfTry);
    },
    [clearGenerateTimeout]
  );

  const generateChart = useCallback(
    (numberOfTry: number = 0) => {
      if (!numberOfTry) numberOfTry = 1;

      configuration.bindto = `#${containerId}`;

      try {
        chartRef.current = generate(configuration);
        if (onGenerate) onGenerate(chartRef.current);
      } catch (e) {
        if (numberOfTry <= 3) {
          setGenerateTimeout(() => {
            generateChart(numberOfTry + 1);
          }, numberOfTry);
        } else {
          console.error("Generating C3 chart failed", e);
        }
      }
    },
    [configuration, containerId, onGenerate, setGenerateTimeout]
  );

  const destroyChart = useCallback(() => {
    clearGenerateTimeout();

    try {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = undefined;
      }
    } catch (e) {
      console.error("Destroying C3 chart failed", e);
    }
  }, [clearGenerateTimeout]);

  useEffect(() => {
    generateChart();

    return () => {
      destroyChart();
    };
  }, [containerId, destroyChart, generateChart]);

  return { containerId, containerRef };
};

export default useChart;
