// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { PrimitiveArray, GridLineOptions, DataPoint } from "c3";
import React from "react";

import { Statuses } from "@apm/endpoint";

import ApplicationErrorMessage from "common/components/messages/ApplicationErrorMessage";
import DataNotFoundMessage from "common/components/messages/DataNotFoundMessage";
import Progress from "common/components/Progress";

import Chart from "./Chart";
import useLineChart from "../hooks/useLineChart";

interface ILineChartProps {
  className?: string;
  mapToColumns: (data: any) => Array<[string, ...PrimitiveArray]>;
  mapToLines?: (data: any) => GridLineOptions[];
  url: string;
  zoom?: boolean;
  tooltip?: boolean;
  tooltipContents?: (
    data: DataPoint[],
    defaultTitleFormat: (...args: unknown[]) => unknown,
    defaultValueFormat: (...args: unknown[]) => unknown,
    color: (...args: unknown[]) => unknown
  ) => string;
}

const LineChart = ({
  className,
  mapToColumns,
  mapToLines,
  url,
  zoom = false,
  tooltip = false,
  tooltipContents
}: ILineChartProps) => {
  const { configuration, response, chartRefContainer } = useLineChart(
    url,
    mapToColumns,
    mapToLines,
    zoom,
    tooltip,
    tooltipContents
  );

  const hasStatusLoading = response && response.status === Statuses.Loading;
  const hasStatusSucceeded = response && response.status === Statuses.Succeeded;
  const hasStatusNotFound = response && response.status === Statuses.NotFound;
  const hasStatusFailed = response && response.status === Statuses.Failed;
  const hasData = response && response.data;

  return (
    <div className={className}>
      {hasStatusLoading && <Progress />}
      {hasStatusSucceeded && hasData && (
        <div ref={chartRefContainer}>
          <Chart configuration={configuration} />
        </div>
      )}
      {hasStatusSucceeded && !hasData && <DataNotFoundMessage />}
      {hasStatusNotFound && <DataNotFoundMessage />}
      {hasStatusFailed && <ApplicationErrorMessage />}
    </div>
  );
};

export default LineChart;
